import React, { Component } from 'react'
import footerLogo from "../images/foterLogo.png"
import {Link} from 'react-router-dom';
import axios from "axios";
import keys from "../actions/config";
import {footerSetting} from "../actions/authactions";

const url = keys.baseUrl;
class Footer extends Component{
  constructor(props) {
    super(props);
    this.state = {
      facebook: "",
      instagram: "",
      skype:"",
      linkedin:"",
      twitter:"",
      telegram:"",
      whatsapp:"",
      skype:""
    };
  }
  componentDidMount() {
    axios
    .get(url + "api/eggroup-setting-get")
    .then((res) => {
      if(res.data.length > 0){
        this.setState({
          facebook: res.data[0].facebook,
          instagram: res.data[0].instagram,
          skype:res.data[0].skype,
          linkedin:res.data[0].linkedin,
          twitter:res.data[0].twitter,
          telegram:res.data[0].telegram,
          whatsapp:res.data[0].whatsapp
        })
      }else{
        this.setState({
          facebook: "",
          instagram: "",
          skype:"",
          linkedin:"",
          twitter:"",
          telegram:"",
          whatsapp:""
        })
      }
     
  
      console.log(this.state);
    })
    .catch();
  }
 
	render(){
			return (<div>
        <footer className="mainFooter">
      <div className="container">
        <div className="row">
          <div className="col-xl-2 col-lg-2 col-md-3">
            <div className="footerLogo">
              <a href="#" className="d-block text-right"><img src={ footerLogo } className="img-fluid" alt="" /></a>
            </div>
          </div>
          <div className="col-xl-6 col-lg-7 col-md-6">
            <div className="footerLinks">
              <h6>RECENT POSTS</h6>
              <p><a href="/PodcastSeries">Podcast Series</a> | <a href="/Career">Career Services</a>  |  <a href="/pdf/Whitepaper.pdf" download >GenesisONE Platform WhitePaper</a></p>
            </div>
            <div className="footerLinks">
              <h6>Quick Links</h6>
              <p><a href="/">Home</a> | <a href="/About">About</a> | <a href="/Vision">Vision</a> | <a href="/StrategyAndGoals">Strategy and Goals</a> | <a href="/EnterMembership">Enter Membership</a> | <a href="/Contact">Contact</a></p>
            </div>
            <div className="footerLinks">
              <p><small>© Copyright  2020, All Rights Reserved </small>
</p>
            </div>
          </div>
          <div className="col-xl-4 col-lg-3 col-md-3">
            <div className="footerSocialLinks">
              <ul>
                <li><a href={this.state.facebook} target="_blank"><i className="fab fa-facebook"></i></a></li>
                <li><a href={this.state.instagram} target="_blank"><i className="fab fa-instagram"></i></a></li>
                <li><a href={this.state.linkedin} target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                <li><a href={this.state.twitter} target="_blank"><i className="fab fa-twitter"></i></a></li>
                <li><a href={this.state.telegram} target="_blank"><i className="fab fa-telegram-plane"></i></a></li>
                <li><a href={this.state.whatsapp} target="_blank"><i className="fab fa-whatsapp"></i></a></li>
                <li><a href={this.state.skype} target="_blank"><i className="fab fa-skype"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
        </div>
);
	};
}

export default Footer;