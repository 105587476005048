module.exports = {


// demo
  // baseUrl: "http://18.218.61.140:5000/",

   //live site
  // baseUrl: "http://172.104.166.247:5000/",
  baseUrl : "https://genesisonetoken.com:2053/",

  //  baseUrl : "http://localhost:5000/",

  // imageUrl : "https://localhost.com/",
  // socketUrl : "http://localhost:5000/",
  // socketUrl: "http://18.218.61.140:5000/",
  // refurl: "http://18.218.61.140/",


  refurl: "https://genesisonetoken.com/",

  // refurl: "http://localhost:3000/",

  Recaptchakey: "6LdpeoQUAAAAAHwFEDfpcA-W5-leSH8548lZWWeb", //local
};
