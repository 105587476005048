import React, { Component } from 'react'
import PageNavbar from './PageNavbar'
import Footer from './Footer'
import AboutCoin from "../images/aboutCoin.png"
import AboutImgWeight from "../images/aboutimgWeight.png"
import AboutBook from "../images/aboutBook.png"
import ReactDOM from 'react-dom';


class About extends Component {
	render() {
		return (
      <div>
      <PageNavbar />
      <section className="abtBanner"></section>
      <section className="aboutCoin">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="aboutCoinImg">
              <img src={ AboutCoin } className="img-fluid d-block mx-auto" alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="aboutCoinContent">
              <p>EG Group in seeking to become a paragon in the decentralized structure of Blockchain and Cryptocurrency and are creating a forum where like-minded individuals globally, can come together as ONE to utilize this forum for their own choosing.</p>
              <p>Where they are free to participate in new, transformative and alternate coin, token and currency platforms that enable them to achieve their desired value.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="aboutDouble">
      <div className="row mx-0">
        <div className="col-xl-6 col-lg-12 px-0">
          <div className="aboutDoubleImg clearfix">
             <div className="adlContent">
              <img src={ AboutImgWeight } className="img-fluid d-block mx-auto" alt="" />
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 px-0">
          <div className="aboutDoubleText">
              <p>Everyone has their own perspectives on the type of value they may derive; however dynamic it is, the individual essential right to be involved in a community that avails itself to providing the most central good, over individual financial gains. We are not a forum for speculation or the mechanisms that drive prices beyond the-means of everyone who chooses to be involved, to be able to become involved. We are not seeking to pursue a cause of unrealistic valuations, whereby the underlying asset or service is multiple times it’s tangible value.</p>
              <p>We are looking to create a more stable currency that then can lend itself to becoming as ubiquitous as fiat currencies. Such, as the current state of cryptocurrency has become, rife with speculation,fears of missing out (FOMO), fears over regulation, regulation increasing costs hundred-fold, scarcity of tokens in the hands of few people, and limited access, all providing barriers to entry.Whereby, true planners of long term strategy for developing a system as closely related to the premise that Satoshi Nakamoto had built the original Bitcoin system on. Currently, no different than fiat capital markets, only a small few of the global population has the access and the finances to participate, offer up innovative systems, and derive true value from cryptocurrency.</p>
          </div>
        </div>
      </div>
    </section>
    <section className="aboutDouble alternateDouble">
      <div className="row mx-0">
        <div className="col-xl-6 col-lg-12 px-0">
            <div className="adlContent">
              <p>That is contrarian to the original thinking behind cryptocurrency, as is the consensus behind global regulation that is now attempting to provide a structure to the market that otherwise its creators sought to avoid. The basis for that is such, that the pervasive view of a centralized regulatory body for cryptocurrency markets can be argued that they are no different than fiat systems and how they exert an overwhelming undue influence on the course of everyday life.</p>
              <p>Politics, macroeconomic theory, regulation, monetary policy, speculation and arbitrage are for the most part tools that drive fiat markets and for the most part what puts them out of most people’s reaches. As always, a few bad actors, bring forth the heavy hand of governance which through regulationoften adds insurmountable costs to achieving a viable good and as good as serve as impetus to the demise of an ideology and principle.</p>
              </div>
        </div>
        <div className="col-xl-6 col-lg-12 px-0">
          <div className="aboutDoubleImg py-5 mr-0">
              <div className="adlRightImg">
                <img src={ AboutBook } className="img-fluid d-block mx-auto" alt="" />
              </div>
          </div>
        </div>
      </div>
    </section>
    <section className="footerTopDouble">
      <div className="row mx-0">
        <div className="col-md-6 px-0">
          <div className="bgColorDarkPurple"></div>
        </div>
        <div className="col-md-6 px-0">
           <div className="bgColorLightPurple"></div>
        </div>
      </div>
    </section>
    <Footer />
</div>
);
	}
}

export default About