import React, { Component } from 'react'
import PageNavbar from './PageNavbar'
import Footer from './Footer'
import careerImg from "../images/careerImg.jpg"
import ReactDOM from 'react-dom';


class Career extends Component {
	render() {
		return (
      <div>
      <PageNavbar />
      <section className="careerBanner"></section>
    <section className="postContent">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 mb-4 mb-lg-0">
            <div className="postImg">
              <img src={ careerImg } className="img-fluid d-block mx-auto" alt="" />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="postText">
              <p>Ready to be a part of a divergent market looking to create true dynamic transformative change on a global scale; then send us your CV or portfolio clearly detailing your educational background, credentials, and experience to:</p>
              <h4>Career Services</h4>
              <p>We are expanding and looking for:</p>
              <ul>
                <li><i className="fas fa-circle"></i> Experienced C-Suite Professionals to join our core</li>
                <li><i className="fas fa-circle"></i> Software/App programmers, coders, and designers</li>
                <li><i className="fas fa-circle"></i> Web designers and developers</li>
                <li><i className="fas fa-circle"></i> Account Executives and Managers</li>
                <li><i className="fas fa-circle"></i> Global Affiliate Managers</li>
                <li><i className="fas fa-circle"></i> Administrative Staff and Interns</li>
              </ul>
              <p>Be prepared for an initial video conferencing interview following your resume submission.</p>
              <p>Check our social media profiles on Facebook, Instagram, Twitter and LinkedIn for more information as well as Career Sites: Indeed and ZipRecruiter.</p>
              
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="footerTopDouble">
      <div className="row mx-0">
        <div className="col-md-6 px-0">
          <div className="bgColorDarkPurple"></div>
        </div>
        <div className="col-md-6 px-0">
           <div className="bgColorLightPurple"></div>
        </div>
      </div>
    </section>
    <Footer />
</div>
);
	}
}

export default Career