import React, { Component } from 'react'
import PageNavbar from './PageNavbar'
import Footer from './Footer'
import AboutCoin from "../images/aboutCoin.png"
import ReactDOM from 'react-dom';


class Vision extends Component {
	render() {
		return (
      <div>
      <PageNavbar />
      <section className="appformBanner"></section>
    <section className="appForm">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="aboutCoinImg">
              <img src={ AboutCoin } className="img-fluid d-block mx-auto" alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="aboutCoinContent pt-0">
              <h3 className="mt-0 pt-0">Corporate Pillars</h3>
              <p><span>Foundation of faith –</span> you must first give to God, Hashem, Allah, His part of His provision before partaking in the enjoyment of the fruits of His labor.</p>

              <p><span>Quality –</span> positively always putting your best foot forward in all you do to ensure the output is all that you would want.</p>

              <p><span>Service –</span> assuring to the satisfaction of the intended is paramount to success. Like Quality always strive for excellence in service.</p>

              <p><span>People/Community –</span> those who are of you and around you must always be taken care of. A house in order is a house suitable to be duplicated.</p>

              <p><span>Growth –</span> making sure the seeds sown will reap bountiful harvests for all to benefit greatly to the benefit of all.</p>

              <p><span>Finance –</span> being a good steward assures a steady hand that then allows more weight to be added to balance out the good for a maximum of investment.</p>

              <p><span>Safety –</span> minding your activity and action to ensure that all around see you as a respite from the tumultuousness of life.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="footerTopDouble">
      <div className="row mx-0">
        <div className="col-md-6 px-0">
          <div className="bgColorDarkPurple"></div>
        </div>
        <div className="col-md-6 px-0">
           <div className="bgColorLightPurple"></div>
        </div>
      </div>
    </section>
    <Footer />
</div>
);
	}
}

export default Vision