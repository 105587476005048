import React, { Component } from 'react'
import PageNavbar from './PageNavbar'
import Footer from './Footer'
import ReactDOM from 'react-dom';


class EnterMembership extends Component {
	render() {
		return (
      <div>
      <PageNavbar />
      <section className="appformBanner"></section>
    <section className="appForm">
      <div className="container">
        <h3>Membership Application</h3>
        <p>This application enrolls you in the EG Global Holdings, LLc., Membership Group that is working towards the successful launch of the GenesisOne Token Offering.</p>
        <p>By virtue of the pre-launch, all GenesisOne Tokens reserved and purchased during this drive to membership operational pre-launch window, does receive 10% additional tokens for any quantity purchased at the $1 per pre-GenesisOne Token valuation.</p>
        <h3>Forward-Looking Disclosure</h3>
        <p>Certain statements in this white paper, such as those about future products, services, and features are “forward-looking statements” that are subject to risk and uncertainties. These forward-looking statements are based on current expectations. Various important factors could cause actual results to differ materially. EG Group, LLc, disclaims any obligation to update this data.</p>
        <h3>Disclosure</h3>
        <p>This is not an offer to sell securities nor is it a solicitation to sell securities.</p>
        <p className="text-center py-4"><a href="/MembershipApplication" className="btn btnType1">Enter The Application.</a></p>
        <p>Immediately after electronically signing your documents, a PDF copy will be emailed to you and you will be immediately brought to the storefront to participate in the direct purchase of the GenesisOne Token Reservation during this pre-launch event.Thank you and may the seeds you sow here with your purchase of the GenesisOne Token bear you much fruit throughout our partnership.</p>
      </div>
    </section>
    <section className="footerTopDouble">
      <div className="row mx-0">
        <div className="col-md-6 px-0">
          <div className="bgColorDarkPurple"></div>
        </div>
        <div className="col-md-6 px-0">
           <div className="bgColorLightPurple"></div>
        </div>
      </div>
    </section>
    <Footer />
</div>
);
	}
}

export default EnterMembership