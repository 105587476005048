import React, { Component } from 'react'
import Logo from "../images/eggrouplogo.png"
import {Link} from 'react-router-dom';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';


class PageNavbar extends Component {
  googleTranslateElementInit () {
    //alert("test2")
    /* eslint-disable no-new */
    new window.google.translate.TranslateElement({pageLanguage: 'pt', layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE, autoDisplay: false}, 'google_translate_element');
 }

componentDidMount() {
    // alert("test")

    var addScript = document.createElement('script');
    addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');        
    document.body.appendChild(addScript);  
    window.googleTranslateElementInit = this.googleTranslateElementInit;
}
  constructor(props) {
    super(props);
    this.state = {
      About: "",
      Vision: "",
      Strategy: "",
      Member: "",
    };
  }

  onAbout() {
    this.setState({
      About: "About",
    });
  }
  onVision() {
    this.setState({ Vision: "Vision" });
  }

  onStrategy() {
    this.setState({ Strategy: "Strategy" });
  }

  onMember() {
    this.setState({ Member: "Member" });
  }
  render() {
    return (
    <div>
      <header className="pageHeader">
      <div className="container">
        <nav className="navbar navbar-expand-xl customNav wow fadeInDown" data-wow-delay=".2s">
          <a className="navbar-brand" href="/"><img src={ Logo } /></a>
          <div className="d-block d-xl-none flagToggler">
            <div className="selectFlagSection mr-3">
               
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <a className="nav-link" href="/">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/About">About</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Vision">Vision</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/StrategyAndGoals">Strategy and Goals</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/EnterMembership">Enter Membership</a>
              </li>
              <li className="nav-item">
                <a className="nav-link btn btnType1" href="/Contact">Contact</a>
              </li>
            </ul>
            <div className="selectFlagSection d-none d-xl-block">
               <div id="google_translate_element"></div>
            </div>

            {this.state.Strategy == "Strategy" ? (
              <div class="homebanner">
                <h5>
                  <span>Transformative Change</span>
                </h5>
                <h2>Strategy and Goals</h2>
              </div>
            ) : (
              ""
            )}

            {this.state.Vision == "Vision" ? (
              <div class="homebanner">
                <h5>
                  <span>Simplicity/Risk/Incentive</span>
                </h5>
                <h2>Vision</h2>
              </div>
            ) : (
              ""
            )}
          </div>
          </nav>
          </div>
        </header>
      </div>
    );
  }
}

export default PageNavbar;
