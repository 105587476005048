import React, {Fragment} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/animate.css';
import './css/style.css';
import 'react-notifications-component/dist/theme.css';
import { Provider } from "react-redux";
import store from "./store";
import Landing from './components/Landing'
import About from './components/About'
import Career from './components/Career'
import Contact from './components/Contact'
import EnterMembership from './components/EnterMembership'
import MembershipApplication from './components/MembershipApplication'
import PodcastSeries from './components/PodcastSeries'
import StrategyAndGoals from './components/StrategyAndGoals'
import Vision from './components/Vision'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

class App extends React.Component {
	// googleTranslateElementInit () {
    //     //alert("test2")
    //     /* eslint-disable no-new */
    //     new window.google.translate.TranslateElement({pageLanguage: 'pt', layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE, autoDisplay: false}, 'google_translate_element');
    //  }

    // componentDidMount() {
    //     // alert("test")

    //     var addScript = document.createElement('script');
    //     addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');        
    //     document.body.appendChild(addScript);  
    //     window.googleTranslateElementInit = this.googleTranslateElementInit;
    // }
	render() {
        return (
	<Provider store={store}>
  	<Router basename={'/'}>
	    <Fragment>
	      <Route exact path='/' component={Landing} />
	      <section className='container-fluid px-0'>
		  	{/* <div id="google_translate_element"> */}
				<Switch>
	      		<Route exact path='/About' component={About} />
	      		<Route exact path='/Career' component={Career} />
	      		<Route exact path='/Contact' component={Contact} />
	      		<Route exact path='/EnterMembership' component={EnterMembership} />
	      		<Route exact path='/MembershipApplication' component={MembershipApplication} />
	      		<Route exact path='/PodcastSeries' component={PodcastSeries} />
	      		<Route exact path='/StrategyAndGoals' component={StrategyAndGoals} />
	      		<Route exact path='/Vision' component={Vision} />
	      		</Switch>
			{/* </div> */}
	      </section>
	    </Fragment>
    </Router>
	</Provider>
  );
}
}

export default App;
