import React, { Component } from "react";
import RoundCoinOne from "../images/roundicon1.png";
import RoundCoinTwo from "../images/roundicon2.png";
import RoundCoinThree from "../images/roundicon3.png";
import SlderImgOne from "../images/slderImg1.png";
import SlderImgTwo from "../images/slderImg2.png";
import SlderImgThree from "../images/slderImg3.png";
import NazibRazaq from "../images/nazib_razaq.png";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ReactDOM from "react-dom";

class Landing extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <section className="homeIconBox">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="iconMainBox">
                  <img
                    src={RoundCoinOne}
                    className="img-fluid d-block mx-auto mb-4"
                    alt=""
                  />
                  <div className="iconBox1">
                    <p>
                      At the apex of today, we can effectuate a dawn for
                      tomorrow, a dawn of change and remedy amidst the havoc of
                      life or allow ourselves to remain mired in the nadir of
                      yesterday, seemingly forever bound in the morass of the
                      hallucination of life.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="iconMainBox">
                  <img
                    src={RoundCoinTwo}
                    className="img-fluid d-block mx-auto mb-4"
                    alt=""
                  />
                  <div className="iconBox2">
                    <p>
                      Life as was meant at the dawn of creation, was brought
                      into being for the benefit of all. Life is meant to be
                      shared, enjoyed, and meaningful for the individual, their
                      family, their community, their society, and the global
                      world at large.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="iconMainBox">
                  <img
                    src={RoundCoinThree}
                    className="img-fluid d-block mx-auto mb-4"
                    alt=""
                  />
                  <div className="iconBox3">
                    <p>
                      But why is it that there seems to be a 1% nationally and
                      about the same globally that enjoys life or a facsimile of
                      it. Many brandish their wealth and successes in the face
                      of many who could only dream of life. Why do so many
                      globally suffer and we attempt to apply Band-Aid remedies
                      to a virtual tsunami of chaos.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="homeSlider">
          <div className="container">
            <div
              id="heroLandingSlider"
              className="carousel slide"
              data-ride="carousel"
              data-interval="5000"
            >
              <div
                className="carousel-inner wow fadeIn"
                data-wow-delay="1s"
                role="listbox"
              >
                <div className="carousel-item active">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="carouselImg">
                        <img src={SlderImgOne} className="img-fluid" alt="" />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="carouselText">
                        {/* <p>Micro projects and solutions attempt to do good on a micro level of every day society but throughout countless developing countries the problem is still there festering and rotting everything it touches. What’s worse, the ostentatious performances of wealth and celebrity further propagates the problem by allowing the sown seed of envy to breed and repeat itself.</p>
                                        <p>It manifests it’s self into a myriad of progeny like the obvious of theft, robbery, jealousy, and covetness but also more profoundly is a source for corruption, greed, exploitation, oppression, Injustice and suffering. So, why not look at creating a macro solution to a variety of problems. Think of it as a broad-spectrum antibiotic to what ails us.</p> */}
                        <p>
                          GenesisONE will look at existing businesses to acquire
                          and bring into the GenesisONE platform as well
                          developing businesses according to local resources and
                          needs. This is your opportunity to become part of a
                          global undertaking that serves to increase global
                          wealth by utilizing marginalized assets to develop
                          greater economic and financial centers in neglected
                          areas globally.{" "}
                        </p>
                        <p>
                          This opportunity is a chance to be a part of the
                          solution to fostering greater access to resources,
                          opportunity and development for the world’s most
                          ignored people’s.
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="carouselImg">
                        <img src={SlderImgTwo} className="img-fluid" alt="" />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="carouselText">
                        <p>
                          Over the next several months, many facets of the
                          GenesisONE platform will be deployed globally.
                          Following the initial coin offering for both the
                          GenesisONE Token and GenCoin stable coin the first
                          core business to be launched will be the CyberLottery
                          that allows participants globally to enter major local
                          lotteries like the MegaBall/Powerball in New York, the
                          Florida Lottery, EuroMillions, as well as lotteries in
                          Russia, South Africa and Lottery systems in Asia.
                        </p>
                        <p>
                          Drawings are held according to the Lottery system
                          entries are purchased for and winners can either be
                          individuals or pools who selected the winning numbers
                          of the applicable lottery according to that particular
                          drawing’s prize winnings.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="carouselImg">
                        <img src={SlderImgThree} className="img-fluid" alt="" />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="carouselText">
                        <p>
                          Our engineering teams are also in development with our
                          proprietary Exchange/Wallet platform offering
                          algorithmic and quantitative trading, options, virtual
                          trading of stock/cryptocurrency pairs,
                          futures/cryptocurrency pairs,
                          commodities/cryptocurrency pairs, or fiat
                          currency/crypto trading. Margin accounts as part of
                          the platform will be available for approved Members.
                        </p>
                        <p>
                          Second phase of deployment includes allowing Members
                          to allocate their GenesisONE Tokens to specific
                          current or future projects and deriving a return from
                          chosen business/projects based on individual business
                          performance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#heroLandingSlider"
                role="button"
                data-slide="prev"
              ></a>
              <a
                className="carousel-control-next"
                href="#heroLandingSlider"
                role="button"
                data-slide="next"
              ></a>
            </div>
          </div>
        </section>
        <section className="customerSay">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-10 mx-auto">
                <div className="customerCard">
                  <h4>
                    “If you make no effort and do not strive hard, to advance to
                    a higher level beyond your habitual routine, which has
                    become second nature, you have not achieved through
                    freedom.”
                  </h4>
                  <p>
                    <span>Sage words by an unknown author</span>
                  </p>
                </div>
                <div className="customerCard">
                  <h4>“ I want the world to share in all the prosperity.”</h4>
                  <p>
                    <span>President Xi Jinping – Davos,</span>Switzerland
                    (February, 2017)
                    <br />– regarding the wealth and prosperity occurring in
                    China
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="homeDouble">
          <div className="row mx-0">
            <div className="col-xl-6 col-lg-12 px-0">
              <div className="homeDoubleLeft clearfix">
                <div className="hdlContent">
                  <h4 className="mb-3">Crypto Assets Division</h4>
                  <p>
                    EG Group Consortuim of Global Companies forges ahead with
                    the development and deployment of the GenesisOne Family of
                    Cryptocurrency with the sale, swap, and purchase of Bitcoin
                    – BTC and USDT – Tether (ERC20/TRC20) through our global OTC
                    and Exchange operations and private P2P sales through our
                    direct CryptoAsset Team Members below.No transactions are
                    secure, authorized, sanctioned or approved unless one of the
                    below Team Members are leading the transaction from start to
                    finish. We transact through BTC/USDT Swap, Escrow and Bank
                    to Bank transactions involving some of the world’s major
                    currencies.
                  </p>
                  <p>
                    EG Group Consortuim - in association with a world-wide
                    consortium of affiliated Groups including but not limited
                    to, sovereign Trusts, private financial Institutions, and
                    high net-worth private clients (hereinafter referred to as “
                    <b>EGGI Affiliates</b>”) represent that they are a Provider
                    of Financial Resources including Cash, Bonds and Digital
                    Financial Assets and, from Time-to-Time, desires to enhance
                    its financial position by way of pledging, hypothecating, or
                    leveraging certain of its Assets, for the purpose of secure,
                    structured investments.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 px-0">
              <div className="homeDoubleRight">
                <div className="row">
                  <div className="col-12">
                    <h5 className="mb-3">
                      Genesis Core Team Alpha – Crypto Asset Consortium Providers
                    </h5>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="team_single">
                      <img
                        src={require("../images/team/Picture1.png")}
                        className="img-fluid"
                        alt="Team"
                      />
                      <div className="team_single_content">
                        <h4>James Edouard</h4>
                        <h5>
                          Chief Consortium Crypto Asset Purveyor <br />{" "}
                          President / CEO <br /> EG Group of Global Companies
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6">
                    <div className="team_single">
                      <img
                        src={require("../images/team/Picture2.png")}
                        className="img-fluid"
                        alt="Team"
                      />
                      <div className="team_single_content">
                        <h4>Andre L. Middleton</h4>
                        <h5>Chief Crypto – Provisioner / Co-Mandate</h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6">
                    <div className="team_single">
                      <img
                        src={require("../images/team/Picture3.png")}
                        className="img-fluid"
                        alt="Team"
                      />
                      <div className="team_single_content">
                        <h4>Pieter Scholtz</h4>
                        <h5>Chief Crypto - Manager / Co-Mandate</h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6">
                    <div className="team_single">
                      <img
                        src={require("../images/team/Picture4.png")}
                        className="img-fluid"
                        alt="Team"
                      />
                      <div className="team_single_content">
                        <h4>Clinton Vurden</h4>
                        <h5>Global Crypto Director / Co-Mandate</h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6">
                    <div className="team_single">
                      <img
                        src={require("../images/team/Picture5.png")}
                        className="img-fluid"
                        alt="Team"
                      />
                      <div className="team_single_content">
                        <h4>Linda Anderson</h4>
                        <h5>Crypto Manager / Co-Mandate</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <h5 className="mb-3">Genesis Team Beta – Global Members</h5>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="team_single">
                      <img
                        src={require("../images/team/Picture6.png")}
                        className="img-fluid"
                        alt="Team"
                      />
                      <div className="team_single_content">
                        <h4>David Bullock</h4>
                        <h5>US Crypto Asset Representative</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="team_single">
                      <img
                        src={require("../images/team/Picture7.png")}
                        className="img-fluid"
                        alt="Team"
                      />
                      <div className="team_single_content">
                        <h4>Prebashini Joseph</h4>
                        <h5>Dubai Crypo Assets Representative</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="team_single">
                      <img
                        src={require("../images/team/Picture8.jpg")}
                        className="img-fluid"
                        alt="Team"
                      />
                      <div className="team_single_content">
                        <h4>Dexter Andrada</h4>
                        <h5>Crptocurrency Swap Coordinator</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Landing;
