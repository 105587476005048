import React, { Component } from "react";
import Logo from "../images/eggrouplogo.png";
import HomeBanner from "../images/homebanner.png";
import { Link } from "react-router-dom";

class Navbar extends Component {
  googleTranslateElementInit() {
    //alert("test2")
    /* eslint-disable no-new */
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "pt",
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        autoDisplay: false,
      },
      "google_translate_element"
    );
  }

  componentDidMount() {
    // alert("test")

    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = this.googleTranslateElementInit;
  }
  render() {
    return (
      <div>
        <header className="homeHeader">
          <div className="container">
            <nav
              className="navbar navbar-expand-xl customNav wow fadeInDown"
              data-wow-delay=".2s"
            >
              <a className="navbar-brand" href="/">
                <img src={Logo} />
              </a>
              <div className="d-block d-xl-none flagToggler">
                <div className="selectFlagSection mr-3"></div>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarResponsive"
                  aria-controls="navbarResponsive"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="fas fa-bars"></i>
                </button>
              </div>
              <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item active">
                    <a className="nav-link" href="/">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/About">
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/Vision">
                      Vision
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/StrategyAndGoals">
                      Strategy and Goals
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/EnterMemberShip">
                     Membership
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a  className="nav-link btn btnType1 " href="">
                      Buy Now
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a className="nav-link btn btnType1" href="https://genesisonetoken.com/Login" target="_blank">
                      Buy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link btn btnType1" href="/Contact">
                      Contact
                    </a>
                  </li>
                </ul>
                <div className="selectFlagSection d-none d-xl-block">
                  <div id="google_translate_element"></div>
                </div>
              </div>
            </nav>
            <div className="homebanner">
              <h2>Global Gaming Crypto Payment</h2>
              <h6>Leader of Blockchain Gaming Platform</h6>
              <img
                src={HomeBanner}
                className="img-fluid d-block mx-auto"
                alt=""
              />
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Navbar;
