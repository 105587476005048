import React, { Component } from 'react'
import PageNavbar from './PageNavbar'
import Footer from './Footer'
import ReactDOM from 'react-dom';
import Select from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import classnames from "classnames";
import { store } from "react-notifications-component";
import {egmembership} from "../actions/authactions";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";

const options = [
    
{value: "Afghanistan", label: "Afghanistan"}, 
{value: "land Islands", label: "land Islands"}, 
{value: "Albania", label: "Albania"}, 
{value: "Algeria", label: "Algeria"}, 
{value: "American Samoa", label: "American Samoa"}, 
{value: "AndorrA", label: "AndorrA"}, 
{value: "Angola", label: "Angola"}, 
{value: "Anguilla", label: "Anguilla"}, 
{value: "Antarctica", label: "Antarctica"}, 
{value: "Antigua and Barbuda", label: "Antigua and Barbuda"}, 
{value: "Argentina", label: "Argentina"}, 
{value: "Armenia", label: "Armenia"}, 
{value: "Aruba", label: "Aruba"}, 
{value: "Australia", label: "Australia"}, 
{value: "Austria", label: "Austria"}, 
{value: "Azerbaijan", label: "Azerbaijan"}, 
{value: "Bahamas", label: "Bahamas"}, 
{value: "Bahrain", label: "Bahrain"}, 
{value: "Bangladesh", label: "Bangladesh"}, 
{value: "Barbados", label: "Barbados"}, 
{value: "Belarus", label: "Belarus"}, 
{value: "Belgium", label: "Belgium"}, 
{value: "Belize", label: "Belize"}, 
{value: "Benin", label: "Benin"}, 
{value: "Bermuda", label: "Bermuda"}, 
{value: "Bhutan", label: "Bhutan"}, 
{value: "Bolivia", label: "Bolivia"}, 
{value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina"}, 
{value: "Botswana", label: "Botswana"}, 
{value: "Bouvet Island", label: "Bouvet Island"}, 
{value: "Brazil", label: "Brazil"}, 
{value: "British Indian Ocean Territory", label: "British Indian Ocean Territory"}, 
{value: "Brunei Darussalam", label: "Brunei Darussalam"}, 
{value: "Bulgaria", label: "Bulgaria"}, 
{value: "Burkina Faso", label: "Burkina Faso"}, 
{value: "Burundi", label: "Burundi"}, 
{value: "Cambodia", label: "Cambodia"}, 
{value: "Cameroon", label: "Cameroon"}, 
{value: "Canada", label: "Canada"}, 
{value: "Cape Verde", label: "Cape Verde"}, 
{value: "Cayman Islands", label: "Cayman Islands"}, 
{value: "Central African Republic", label: "Central African Republic"}, 
{value: "Chad", label: "Chad"}, 
{value: "Chile", label: "Chile"}, 
{value: "China", label: "China"}, 
{value: "Christmas Island", label: "Christmas Island"}, 
{value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands"}, 
{value: "Colombia", label: "Colombia"}, 
{value: "Comoros", label: "Comoros"}, 
{value: "Congo", label: "Congo"}, 
{value: "Congo, The Democratic Republic of the", label: "Congo, The Democratic Republic of the"}, 
{value: "Cook Islands", label: "Cook Islands"}, 
{value: "Costa Rica", label: "Costa Rica"}, 
{value: "Cote DIvoire", label: "Cote DIvoire"}, 
{value: "Croatia", label: "Croatia"}, 
{value: "Cuba", label: "Cuba"}, 
{value: "Cyprus", label: "Cyprus"}, 
{value: "Czech Republic", label: "Czech Republic"}, 
{value: "Denmark", label: "Denmark"}, 
{value: "Djibouti", label: "Djibouti"}, 
{value: "Dominica", label: "Dominica"}, 
{value: "Dominican Republic", label: "Dominican Republic"}, 
{value: "Ecuador", label: "Ecuador"}, 
{value: "Egypt", label: "Egypt"}, 
{value: "El Salvador", label: "El Salvador"}, 
{value: "Equatorial Guinea", label: "Equatorial Guinea"}, 
{value: "Eritrea", label: "Eritrea"}, 
{value: "Estonia", label: "Estonia"}, 
{value: "Ethiopia", label: "Ethiopia"}, 
{value: "Falkland Islands (Malvinas)", label: "Falkland Islands (Malvinas)"}, 
{value: "Faroe Islands", label: "Faroe Islands"}, 
{value: "Fiji", label: "Fiji"}, 
{value: "Finland", label: "Finland"}, 
{value: "France", label: "France"}, 
{value: "French Guiana", label: "French Guiana"}, 
{value: "French Polynesia", label: "French Polynesia"}, 
{value: "French Southern Territories", label: "French Southern Territories"}, 
{value: "Gabon", label: "Gabon"}, 
{value: "Gambia", label: "Gambia"}, 
{value: "Georgia", label: "Georgia"}, 
{value: "Germany", label: "Germany"}, 
{value: "Ghana", label: "Ghana"}, 
{value: "Gibraltar", label: "Gibraltar"}, 
{value: "Greece", label: "Greece"}, 
{value: "Greenland", label: "Greenland"}, 
{value: "Grenada", label: "Grenada"}, 
{value: "Guadeloupe", label: "Guadeloupe"}, 
{value: "Guam", label: "Guam"}, 
{value: "Guatemala", label: "Guatemala"}, 
{value: "Guernsey", label: "Guernsey"}, 
{value: "Guinea", label: "Guinea"}, 
{value: "Guinea-Bissau", label: "Guinea-Bissau"}, 
{value: "Guyana", label: "Guyana"}, 
{value: "Haiti", label: "Haiti"}, 
{value: "Heard Island and Mcdonald Islands", label: "Heard Island and Mcdonald Islands"}, 
{value: "Holy See (Vatican City State)", label: "Holy See (Vatican City State)"}, 
{value: "Honduras", label: "Honduras"}, 
{value: "Hong Kong", label: "Hong Kong"}, 
{value: "Hungary", label: "Hungary"}, 
{value: "Iceland", label: "Iceland"}, 
{value: "India", label: "India"}, 
{value: "Indonesia", label: "Indonesia"}, 
{value: "Iran, Islamic Republic Of", label: "Iran, Islamic Republic Of"}, 
{value: "Iraq", label: "Iraq"}, 
{value: "Ireland", label: "Ireland"}, 
{value: "Isle of Man", label: "Isle of Man"}, 
{value: "Israel", label: "Israel"}, 
{value: "Italy", label: "Italy"}, 
{value: "Jamaica", label: "Jamaica"}, 
{value: "Japan", label: "Japan"}, 
{value: "Jersey", label: "Jersey"}, 
{value: "Jordan", label: "Jordan"}, 
{value: "Kazakhstan", label: "Kazakhstan"}, 
{value: "Kenya", label: "Kenya"}, 
{value: "Kiribati", label: "Kiribati"}, 
{value: "Korea, Democratic People\"S Republic of", label: "Korea, Democratic People\"S Republic of"}, 
{value: "Korea, Republic of", label: "Korea, Republic of"}, 
{value: "Kuwait", label: "Kuwait"}, 
{value: "Kyrgyzstan", label: "Kyrgyzstan"}, 
{value: "Lao People\"S Democratic Republic", label: "Lao People\"S Democratic Republic"}, 
{value: "Latvia", label: "Latvia"}, 
{value: "Lebanon", label: "Lebanon"}, 
{value: "Lesotho", label: "Lesotho"}, 
{value: "Liberia", label: "Liberia"}, 
{value: "Libyan Arab Jamahiriya", label: "Libyan Arab Jamahiriya"}, 
{value: "Liechtenstein", label: "Liechtenstein"}, 
{value: "Lithuania", label: "Lithuania"}, 
{value: "Luxembourg", label: "LLuxembourgU"}, 
{value: "Macao", label: "Macao"}, 
{value: "Macedonia, The Former Yugoslav Republic of", label: "Macedonia, The Former Yugoslav Republic of"}, 
{value: "Madagascar", label: "Madagascar"}, 
{value: "Malawi", label: "Malawi"}, 
{value: "Malaysia", label: "Malaysia"}, 
{value: "Maldives", label: "Maldives"}, 
{value: "Mali", label: "Mali"}, 
{value: "Malta", label: "Malta"}, 
{value: "Marshall Islands", label: "Marshall Islands"}, 
{value: "Martinique", label: "Martinique"}, 
{value: "Mauritania", label: "Mauritania"}, 
{value: "Mauritius", label: "Mauritius"}, 
{value: "Mayotte", label: "Mayotte"}, 
{value: "Mexico", label: "Mexico"}, 
{value: "Micronesia, Federated States of", label: "Micronesia, Federated States of"}, 
{value: "Moldova, Republic of", label: "Moldova, Republic of"}, 
{value: "Monaco", label: "Monaco"}, 
{value: "Mongolia", label: "Mongolia"}, 
{value: "Montenegro", label: "Montenegro"},
{value: "Montserrat", label: "Montserrat"},
{value: "Morocco", label: "Morocco"}, 
{value: "Mozambique", label: "Mozambique"}, 
{value: "Myanmar", label: "Myanmar"}, 
{value: "Namibia", label: "Namibia"}, 
{value: "Nauru", label: "Nauru"}, 
{value: "Nepal", label: "Nepal"}, 
{value: "Netherlands", label: "Netherlands"}, 
{value: "Netherlands Antilles", label: "Netherlands Antilles"}, 
{value: "New Caledonia", label: "New Caledonia"}, 
{value: "New Zealand", label: "New Zealand"}, 
{value: "Nicaragua", label: "Nicaragua"}, 
{value: "Niger", label: "Niger"}, 
{value: "Nigeria", label: "Nigeria"}, 
{value: "Niue", label: "Niue"}, 
{value: "Norfolk Island", label: "Norfolk Island"}, 
{value: "Northern Mariana Islands", label: "Northern Mariana Islands"}, 
{value: "Norway", label: "Norway"}, 
{value: "Oman", label: "Oman"}, 
{value: "Pakistan", label: "Pakistan"}, 
{value: "Palau", label: "Palau"}, 
{value: "Palestinian Territory, Occupied", label: "Palestinian Territory, Occupied"}, 
{value: "Panama", label: "Panama"}, 
{value: "Papua New Guinea", label: "Papua New Guinea"}, 
{value: "Paraguay", label: "Paraguay"}, 
{value: "Peru", label: "Peru"}, 
{value: "Philippines", label: "Philippines"}, 
{value: "Pitcairn", label: "Pitcairn"}, 
{value: "Poland", label: "Poland"}, 
{value: "Portugal", label: "Portugal"}, 
{value: "Puerto Rico", label: "Puerto Rico"}, 
{value: "Qatar", label: "Qatar"}, 
{value: "Reunion", label: "Reunion"}, 
{value: "Romania", label: "Romania"}, 
{value: "Russian Federation", label: "Russian Federation"}, 
{value: "RWANDA", label: "RWANDA"}, 
{value: "Saint Helena", label: "Saint Helena"}, 
{value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis"}, 
{value: "Saint Lucia", label: "Saint Lucia"}, 
{value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon"}, 
{value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines"}, 
{value: "Samoa", label: "Samoa"}, 
{value: "San Marino", label: "San Marino"}, 
{value: "Sao Tome and Principe", label: "Sao Tome and Principe"}, 
{value: "Saudi Arabia", label: "Saudi Arabia"}, 
{value: "Senegal", label: "Senegal"}, 
{value: "Serbia", label: "Serbia"}, 
{value: "Seychelles", label: "Seychelles"}, 
{value: "Sierra Leone", label: "Sierra Leone"}, 
{value: "Singapore", label: "Singapore"}, 
{value: "Slovakia", label: "Slovakia"}, 
{value: "Slovenia", label: "Slovenia"}, 
{value: "Solomon Islands", label: "Solomon Islands"}, 
{value: "Somalia", label: "Somalia"}, 
{value: "South Africa", label: "South Africa"}, 
{value: "South Georgia and the South Sandwich Islands", label: "South Georgia and the South Sandwich Islands"}, 
{value: "Spain", label: "Spain"}, 
{value: "Sri Lanka", label: "Sri Lanka"}, 
{value: "Sudan", label: "Sudan"}, 
{value: "Suriname", label: "Suriname"}, 
{value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen"}, 
{value: "Swaziland", label: "Swaziland"}, 
{value: "Sweden", label: "Sweden"}, 
{value: "Switzerland", label: "Switzerland"}, 
{value: "Syrian Arab Republic", label: "Syrian Arab Republic"}, 
{value: "Taiwan, Province of China", label: "Taiwan, Province of China"}, 
{value: "Tajikistan", label: "Tajikistan"}, 
{value: "Tanzania, United Republic of", label: "Tanzania, United Republic of"}, 
{value: "Thailand", label: "Thailand"}, 
{value: "Timor-Leste", label: "Timor-Leste"}, 
{value: "Togo", label: "Togo"}, 
{value: "Tokelau", label: "Tokelau"}, 
{value: "Tonga", label: "Tonga"}, 
{value: "Trinidad and Tobago", label: "Trinidad and Tobago"}, 
{value: "Tunisia", label: "Tunisia"}, 
{value: "Turkey", label: "Turkey"}, 
{value: "Turkmenistan", label: "Turkmenistan"}, 
{value: "Turks and Caicos Islands", label: "Turks and Caicos Islands"}, 
{value: "Tuvalu", label: "Tuvalu"}, 
{value: "Uganda", label: "Uganda"}, 
{value: "Ukraine", label: "Ukraine"}, 
{value: "United Arab Emirates", label: "United Arab Emirates"}, 
{value: "United Kingdom", label: "United Kingdom"}, 
{value: "United States", label: "United States"}, 
{value: "United States Minor Outlying Islands", label: "United States Minor Outlying Islands"}, 
{value: "Uruguay", label: "Uruguay"}, 
{value: "Uzbekistan", label: "Uzbekistan"}, 
{value: "Vanuatu", label: "Vanuatu"}, 
{value: "Venezuela", label: "Venezuela"}, 
{value: "Viet Nam", label: "Viet Nam"}, 
{value: "Virgin Islands, British", label: "Virgin Islands, British"}, 
{value: "Virgin Islands, U.S.", label: "Virgin Islands, U.S."}, 
{value: "Wallis and Futuna", label: "Wallis and Futuna"}, 
{value: "Western Sahara", label: "Western Sahara"}, 
{value: "Yemen", label: "Yemen"}, 
{value: "Zambia", label: "Zambia"}, 
{value: "Zimbabwe", label: "Zimbabwe"} 
];
class MembershipApplication extends Component {
  state = {
    selectedOption: null,
    first:'',
    middle:'',
    last:'',
    tellus:'',
    address1:'',
    address2:'',
    city:'',
    state:'',
    zip:'',
    email:'',
    password:'',
    cpassword:'',
    residence:'',
    ownRrent:'',
    number:'',
    cell:'',
    dob:'',
    datePickerIsOpen:false,
    errors: {}
  };
  mySubmitHandler = (event) => {
    this.props.egmembership(this.state, this.props.history);
    event.preventDefault();
  }
  onChangeDate = date => {
    this.setState({
      dob: date
    });
  };

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };
  focusCal = e =>{
    this.setState({
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  }
  openCal = e =>{
    this.setState({
      datePickerIsOpen: true,
    });
  }
  
  closeCal = e =>{
    this.setState({
      datePickerIsOpen: false,
    });
  }
  handleChange = selectedOption => {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
  };
  componentWillReceiveProps(nextProps) {
    console.log('nextprops in Register ',nextProps);

    if(Object.keys(nextProps.auth.egmembership).length>0){
      toast.success(nextProps.auth.egmembership.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      this.setState({
        selectedOption: null,
        first:'',
        middle:'',
        last:'',
        tellus:'',
        address1:'',
        address2:'',
        city:'',
        state:'',
        zip:'',
        email:'',
        password:'',
        cpassword:'',
        residence:'',
        ownRrent:'',
        number:'',
        cell:'',
        dob:'',
        datePickerIsOpen:false,
          errors: {}
      });
      nextProps.auth.egmembership={}
    }else if(nextProps.errors){
      this.setState({
        errors: nextProps.errors
    });
    }
  }
	render() {
    const { selectedOption } = this.state;
		return (
      <div>
      <PageNavbar />
      <section className="appformBanner"></section>
    <section className="appForm">
      <div className="container">
      <form onSubmit={this.mySubmitHandler}>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label>First Name <sup>*</sup></label>
              <input type="text" 
              className="form-control" 
              name="first" 
              id="first" 
              value={this.state.first}
              onChange={this.onChange}
              error={this.state.errors.first}
              className={classnames("form-control", {
                invalid: this.state.errors.first
            })}  
              />
               <span className="text-danger">{this.state.errors.first}</span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Middle Name</label>
              <input type="text" 
              className="form-control" 
              name="middle" 
              id="middle" 
              value={this.state.middle}
              onChange={this.onChange}/>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Last Name <sup>*</sup></label>
              <input type="text" 
              className="form-control" 
              name="last" 
              id="last" 
              value={this.state.last}
              onChange={this.onChange}
              error={this.state.errors.last}
              className={classnames("form-control", {
                invalid: this.state.errors.last
            })}  
              />
               <span className="text-danger">{this.state.errors.last}</span>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>Please tell us how or by whom did you hear of EG Group Holdings and GenesisOne <sup>*</sup></label>
              <input type="text" 
              className="form-control" 
              name="tellus" 
              id="tellus" 
              value={this.state.tellus}
              onChange={this.onChange}
              error={this.state.errors.tellus}
              className={classnames("form-control", {
                invalid: this.state.errors.tellus
            })}  
              />
               <span className="text-danger">{this.state.errors.tellus}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Address 1 <sup>*</sup></label>
              <input type="text" 
              className="form-control" 
              name="address1" 
              id="address1" 
              value={this.state.address1}
              onChange={this.onChange}
              error={this.state.errors.address1}
              className={classnames("form-control", {
                invalid: this.state.errors.address1
            })}  
              />
               <span className="text-danger">{this.state.errors.address1}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Address 2 <sup>*</sup></label>
              <input type="text" 
              className="form-control" 
              name="address2" 
              id="address2" 
              value={this.state.address2}
              onChange={this.onChange}
              error={this.state.errors.address2}
              className={classnames("form-control", {
                invalid: this.state.errors.address2
            })}  
              />
               <span className="text-danger">{this.state.errors.address2}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>City <sup>*</sup></label>
              <input type="text" 
              className="form-control" 
              name="city" 
              id="city" 
              value={this.state.city}
              onChange={this.onChange}
              error={this.state.errors.city}
              className={classnames("form-control", {
                invalid: this.state.errors.city
            })}  
              />
               <span className="text-danger">{this.state.errors.city}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>State / Province / Region <sup>*</sup></label>
              <input type="text" 
              className="form-control" 
              name="state" 
              id="state" 
              value={this.state.state}
              onChange={this.onChange}
              error={this.state.errors.state}
              className={classnames("form-control", {
                invalid: this.state.errors.state
            })}  
              />
               <span className="text-danger">{this.state.errors.state}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>ZIP / Postal Code <sup>*</sup></label>
              <input type="text" 
              className="form-control" 
              name="zip" 
              id="zip" 
              value={this.state.zip}
              onChange={this.onChange}
              error={this.state.errors.zip}
              className={classnames("form-control", {
                invalid: this.state.errors.zip
            })}  
              />
               <span className="text-danger">{this.state.errors.zip}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group clearfix">
              <label>Country <sup>*</sup></label>
              <Select
                        // onChange={this.state.country}
                        value={selectedOption}
                        onChange={this.handleChange}
                        options={options}
                        defaultValue={this.state.country}
                        label="Single select"
                        theme={theme => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#ffba00",
                            primary: "#ffba00"
                          }
                        })}
                        error={this.state.errors.country}
                        />
                         <span className="text-danger">{this.state.errors.country}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Email address <sup>*</sup></label>
              <input type="text" 
              className="form-control" 
              name="email" 
              id="email"
              value={this.state.email}
              onChange={this.onChange}
              error={this.state.errors.email}
              className={classnames("form-control", {
                invalid: this.state.errors.email
            })}  
              />
               <span className="text-danger">{this.state.errors.email}</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Password <sup>*</sup></label>
              <input type="password" 
              className="form-control" 
              name="password" 
              id="password" 
              value={this.state.password}
              onChange={this.onChange}
              error={this.state.errors.password}
              className={classnames("form-control", {
                invalid: this.state.errors.password
            })}  
              />
               <span className="text-danger">{this.state.errors.password}</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Confirm Password <sup>*</sup></label>
              <input type="password" 
              className="form-control" 
              name="cpassword" 
              id="cpassword" 
              value={this.state.cpassword}
              onChange={this.onChange}
              error={this.state.errors.cpassword}
              className={classnames("form-control", {
                invalid: this.state.errors.cpassword
            })}  
              />
               <span className="text-danger">{this.state.errors.cpassword}</span>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="form-group">
              <label>Type of residence? Is this an apartment/condo or a home <sup>*</sup></label>
              <input type="text" 
              className="form-control" 
              name="residence" 
              id="residence" 
              value={this.state.residence}
              onChange={this.onChange}
              error={this.state.errors.residence}
              className={classnames("form-control", {
                invalid: this.state.errors.residence
            })}  
              />
               <span className="text-danger">{this.state.errors.residence}</span>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="form-group">
              <label>Do you own, lease, or rent your residence <sup>*</sup></label>
              <input type="text" 
              className="form-control" 
              name="ownRrent" 
              id="ownRrent" 
              value={this.state.ownRrent}
              onChange={this.onChange}
              error={this.state.errors.ownRrent}
              className={classnames("form-control", {
                invalid: this.state.errors.ownRrent
            })}  
              />
               <span className="text-danger">{this.state.errors.ownRrent}</span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Telephone Number</label>
              <input type="text" 
              className="form-control" 
              name="number" 
              id="number" 
              value={this.state.number}
              onChange={this.onChange}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Mobile/Cell phone <sup>*</sup></label>
              <input type="text" 
              className="form-control" 
              name="cell" 
              id="cell" 
              value={this.state.cell}
              onChange={this.onChange}
              error={this.state.errors.cell}
              className={classnames("form-control", {
                invalid: this.state.errors.cell
            })}  
              />
               <span className="text-danger">{this.state.errors.cell}</span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input-group">
              <label>Date of birth <sup>*</sup></label>
              <DatePicker 
              className="datePickerMain"
              selected={this.state.dob}
              name="dob" 
              id="dob"
              open={this.state.datePickerIsOpen}
              value={this.state.dob}
              onSelect={this.closeCal} 
              onChange={this.onChangeDate} 
              onBlur={this.focusCal}
              onFocus={this.openCal}
              icon={<i className="cal-icon"></i>}
              error={this.state.errors.dob}
              className="form-control datepicker"
              />
              <div className="input-group-append" onClick={this.focusCal} onBlur={this.closeCal} >
                    <i  className="far fa-calendar-alt"></i>
              </div>
              <span className="text-danger">{this.state.errors.dob}</span>
              {/* <div className="datePickerMain">
                <input type="text" 
                data-date-format="dd/mm/yyyy" 
                name="dob" 
                id="dob" 
                value={this.state.dob}
                onChange={this.onChange} 
                className="form-control datepicker" 
                error={this.state.errors.dob}
                className={classnames("form-control", {
                  invalid: this.state.errors.dob
              })}
                />
                <div className="input-group-append">
                    <i className="far fa-calendar-alt"></i>
                </div>
                <span className="text-danger">{this.state.errors.dob}</span>
              </div> */}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group text-center">
              <input type="submit" className="btn btnTypeSubmit" value="Submit" name="" />
            </div>
          </div>
        </div>
        </form>
      </div>
    </section>
    <section className="footerTopDouble">
      <div className="row mx-0">
        <div className="col-md-6 px-0">
          <div className="bgColorDarkPurple"></div>
        </div>
        <div className="col-md-6 px-0">
           <div className="bgColorLightPurple"></div>
        </div>
      </div>
      <ToastContainer />
    </section>
    <Footer />
</div>
);
	}
}


MembershipApplication.propTypes = {
  egmembership: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};


const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { egmembership }
)(withRouter(MembershipApplication));