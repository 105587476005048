import React, { Component } from 'react'
import PageNavbar from './PageNavbar'
import Footer from './Footer'
import ReactDOM from 'react-dom';
import ResponsiveEmbed from 'react-responsive-embed'

class PodcastSeries extends Component {
	render() {
		return (
      <div>
      <PageNavbar />
      <section className="appformBanner"></section>
    <section className="appForm">
      <div className="container">
        <p>Welcome to our portal for all things related to educating everyone on the GenesisONE platform.</p>
        <p>Please click on corresponding link to be taken directly to your selection. CoinSchedule TV – What is GenesisONE Token?</p>
        <div className="row my-4">
          <div className="col-lg-7">
            <ResponsiveEmbed src='https://www.youtube.com/embed/HGNuYUYN5dE' allowfullscreen />
          </div>
        </div>
        <p>Join us and view our series – CoffeeHouse Chats with GenesisONE and gain insight into our platform.</p>
        <p>This is a series dedicated to bringing light to cryptocurrency and understanding of the strategy and vision of GenesisONE!</p>
        <div className="row my-4">
          <div className="col-lg-7">
            <ResponsiveEmbed src='https://www.youtube.com/embed/qHjAJGs2r9A' allowfullscreen />
          </div>
        </div>
      </div>
    </section>
    <section className="footerTopDouble">
      <div className="row mx-0">
        <div className="col-md-6 px-0">
          <div className="bgColorDarkPurple"></div>
        </div>
        <div className="col-md-6 px-0">
           <div className="bgColorLightPurple"></div>
        </div>
      </div>
    </section>
    <Footer />
</div>
);
	}
}

export default PodcastSeries